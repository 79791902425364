.selectCities > .MuiSelect-selectMenu {
    background-color: white !important;
    border: 1px solid #d8d8d8 !important;
    color: #131313 !important;
    white-space: pre-wrap !important;
    min-height: 35px !important;
}

.selectCities > .MuiSelect-selectMenu input::placeholder {
    font-size: 1rem !important;
    color: #131313 !important;
}

.selectCities > .MuiSelect-selectMenu .MuiAutocomplete-input:first-child {
    color: #181818 !important;
    font-weight: 300 !important;
}

.selectCities > .MuiSelect-selectMenu label.Mui-focused {
    border-width: 1px !important;
    border-color: #d8d8d8 !important;
}

.selectCities > .MuiSelect-selectMenu .MuiInput-underline:after {
    border-bottom-color: #d8d8d8 !important;
}

.selectCities > .MuiSelect-selectMenu .MuiFilledInput-underline:after {
    border-bottom-color: #d8d8d8 !important;
}

.selectCities > .MuiSelect-selectMenu .MuiOutlinedInput-root.Mui-focused fieldset {
    border-width: 1px !important;
    border-color: #d8d8d8 !important;
}

.selectCities > .MuiSelect-selectMenu {
    border-bottom: none !important;
}

.MuiInput-underline {
    border-bottom: none !important;
}

.MuiInput-input {
    border-bottom: none !important;
}